/* eslint-disable jsx-a11y/iframe-has-title */
import { useResizeWidth } from '@hooks/useResizeWidth'
import { FC, useRef } from 'react'

import styles from './ContactBlock.module.css'

const ContactBlock: FC = () => {
	const widthRef = useRef<HTMLDivElement>(null)
	const width = useResizeWidth(widthRef)

	return (
		<div className={styles.wrapper}>
			<div className={styles.container} ref={widthRef}>
				<div className={styles.info}>
					<h3 className={styles.title}>Contacts</h3>
					<div className={styles.block}>
						If you have any questiongs write to DM Instagram @Ubud.Space
					</div>
					<div className={styles.block}>
						<span>pr@ubud.space</span>
					</div>
					<a target="_blank" href="https://maps.app.goo.gl/a6b6RePp5vkgvxXP6">
						<div className={styles.block}>
							<span>Ubud</span>
							<span>Kecamatan Ubud</span>
							<span>Kabupaten Gianyar</span>
							<span>Bali 80571</span>
						</div>
					</a>
					<div className={styles.link__box}>
						<a href='https://www.instagram.com/ubud.space?igsh=MzRlODBiNWFlZA==' className={styles.link}>
							Instagram
						</a>
					</div>
				</div>
				<iframe
					style={{
						border: 'none',
						borderRadius: '24px',
						width: `${width - 70}px`,
						height: '393px'
					}}
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.937626005425!2d115.2586875!3d-8.505437500000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23d36c1fe7863%3A0x2a96fb8313d465e0!2sUbud.Space%20Coworking%20and%20Coffee!5e0!3m2!1sru!2sru!4v1711468238795!5m2!1sru!2sru'
					height='393'
					loading='lazy'
					referrerPolicy='no-referrer-when-downgrade'
				></iframe>
			</div>
		</div>
	)
}

export default ContactBlock
