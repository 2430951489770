import { FC, useState } from 'react'

import ContactBlock from './contacts-block'
import FaqBlock from './faq-block'
import RentBlock from './rent-block'
import WelcomeBlock from './welcome-block'

const App: FC = () => {
	const [active, setActive] = useState<boolean>(false)
	return (
		<>
			<WelcomeBlock />
			<RentBlock active={active} setActive={setActive} />
			<ContactBlock />
		</>
	)
}

export default App
