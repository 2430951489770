import { FC } from 'react'

import Modal from '@components/modal'
import Slider from '@components/slider'

import styles from './RentBlock.module.css'

interface IRentBlock {
	active: boolean
	setActive: any
}

const items = [
	{
		title: 'Open Space',
		price: '30 000 Rp / Hour',
		content: 'Suitable for those who want to concentrate on solving work and educational tasks for one hour'
	},
	{
		title: 'Open Space',
		price: '150 000 Rp / Day',
		content: 'Suitable for those who want to concentrate on solving work and educational tasks'
	},
	{
		title: 'Small meeting room',
		price: '60 000 Rp / Hour',
		content: 'Suitable for 1 person having a video or audio call'
	}
]

const items2 = [
	{
		title: 'Medium meeting room',
		price: '100 000 Rp / Hour',
		content: 'Suitable for those who want to have a conference call or hold a meeting with a group of 2 to 6 people'
	},
	{
		title: 'Big meeting room',
		price: '200 000 Rp / Hour',
		content: 'Suitable if you want to host a small event for up to 10 people or work alone in a cozy space'
	},
	{
		title: 'Monitor',
		price: '50 000 Rp / Day',
		content: 'You can rent a monitor for your use for the entire day'
	}
]

const RentBlock: FC<IRentBlock> = ({ active, setActive }) => {
	const onBtnClick = () => {
		setActive(!active)
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.position}>
					<h3 className={styles.title} id='tarif'>
						Book now
					</h3>
					<div className={styles.item__wrapper}>
						{items.map((item, index) => (
							<div key={index} className={styles.item}>
								<p className={styles.item__title}>{item.title}</p>
								<p className={styles.list__wrapper}>{item.content}</p>
								<p className={styles.price}>{item.price}</p>
								<a href="https://wa.me/628113859118" target="_blank" className={styles.btn}>
									Reserve
								</a>
							</div>
						))}

						{active && <Modal active={active} setActive={setActive} />}
					</div>
					<div className={styles.item__wrapper}>
						{items2.map((item, index) => (
							<div key={index} className={styles.item}>
								<p className={styles.item__title}>{item.title}</p>
								<p className={styles.list__wrapper}>{item.content}</p>
								<p className={styles.price}>{item.price}</p>
								<a href="https://wa.me/628113859118" target="_blank" className={styles.btn}>
									Reserve
								</a>
							</div>
						))}

						{active && <Modal active={active} setActive={setActive} />}
					</div>
				</div>

				<Slider />
			</div>
		</div>
	)
}

export default RentBlock
