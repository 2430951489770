/* eslint-disable react-hooks/exhaustive-deps */
import { useResizeWidth } from '@hooks/useResizeWidth'
import { FC, useRef } from 'react'

import Banner from '@components/banner'
import Header from '@components/header'

import styles from './WelcomeBlock.module.css'

const WelcomeBlock: FC = () => {
	const widthRef = useRef<HTMLDivElement>(null)
	const width = useResizeWidth(widthRef)

	return (
		<div className={styles.wrapper} ref={widthRef}>
			<div className={styles.container}>
				{width > 1025 && <Header />}
				<Banner width={width} />
			</div>
		</div>
	)
}

export default WelcomeBlock
