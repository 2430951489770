export const useSetPreview = (width: number) => {
	if (width <= 370) {
		return 1
	}

	if (width <= 400) {
		return 1.25
	}

	if (width <= 425) {
		return 1.35
	}

	if (width <= 470) {
		return 1.45
	}

	if (width <= 500) {
		return 1.55
	}

	if (width <= 530) {
		return 1.65
	}

	if (width <= 560) {
		return 1.75
	}

	if (width <= 590) {
		return 1.85
	}

	if (width <= 620) {
		return 1.95
	}
	if (width <= 700) {
		return 2
	}

	if (width <= 750) {
		return 2.2
	}

	if (width <= 830) {
		return 2.5
	}

	if (width <= 950) {
		return 2.7
	}

	if (width <= 1050) {
		return 3
	}

	if (width <= 1150) {
		return 3.2
	}

	if (width <= 1250) {
		return 3.5
	}

	if (width <= 1350) {
		return 3.7
	}

	if (width <= 1440) {
		return 4
	}
}
