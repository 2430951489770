/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

export const useResizeWidth = (ref: any) => {
	const [width, setWidth] = useState(0)

	const resizeHandler = () => {
		const rect = ref?.current?.getBoundingClientRect()
		if (rect) {
			setWidth(rect.width)
		}
	}

	useEffect(() => {
		window.addEventListener('resize', resizeHandler)
		resizeHandler()
		return () => {
			window.removeEventListener('resize', resizeHandler)
		}
	}, [resizeHandler])

	return width
}
