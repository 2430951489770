import { FC } from 'react'

import logo from '@assets/logo.svg'

import styles from './Header.module.css'

const Header: FC = () => {
	return (
		<header className={styles.header}>
			<img src={logo} alt='logo' />
		</header>
	)
}

export default Header
