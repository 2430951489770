import { FC } from 'react'

import bar from '@assets/bar.svg'
import calendar from '@assets/calendar.svg'
import desk from '@assets/desk.svg'
import logo from '@assets/logo-white.svg'
import wifi from '@assets/wifi.svg'

import styles from './Banner.module.css'

const data = [
	{
		logo: calendar,
		title: '9:00 - 23:30',
		subtitle: 'Monday-Sunday'
	},
	{
		logo: wifi,
		title: '200 mb/s',
		subtitle: 'Stable internet'
	},
	{
		logo: desk,
		title: 'Open space and',
		subtitle: 'meeting rooms'
	}
]

interface IBunner {
	width: number
}

const Banner: FC<IBunner> = ({ width }) => {
	return (
		<div className={styles.banner}>
			<div className={styles.blackout}></div>
			{width < 1026 && (
				<div className={styles.header}>
					<img src={logo} alt='log' />
				</div>
			)}
			<div className={styles.wrapper}>
				<div className={styles.title}>Coworking in the center of Ubud</div>
				<div className={styles.subtitle}>
					A place where you can focus on completing tasks from your to-do list
				</div>
				<div className={styles.features__box}>
					{data.map((item, index) => (
						<div key={index} className={styles.items__wrapper}>
							<img className={styles.logo} src={item.logo} alt='logo' />
							<div className={styles.feature__item}>
								<p className={styles.feature__title}>{item.title}</p>
								<p className={styles.feature__subtitle}>{item.subtitle}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Banner
