import { FC } from 'react'

import styles from './Modal.module.css'

interface IModalBlock {
	active: boolean
	setActive: any
}

const Modal: FC<IModalBlock> = ({ active, setActive }) => {
	return (
		<div className={styles.modal} onClick={() => setActive(false)}>
			<div className={styles.modal__content} onClick={e => e.stopPropagation()}>
				<p className={styles.title}>Заказать место</p>
				<p className={styles.subtitle}>
					Оставьте заявку и мы свяжемся с вами, чтобы забронировать место
				</p>
				<input type='text' placeholder='Как вас зовут?' />
				<input type='text' placeholder='Номер телефона' />
				<button className={styles.btn} onClick={() => setActive(false)}>
					Заказать
				</button>
			</div>
		</div>
	)
}

export default Modal
