import { useResizeWidth } from '@hooks/useResizeWidth'
import { useSetPreview } from '@hooks/useSetPreview'
import { FC, useRef } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import icon_2 from '@assets/calendar.svg'
import icon_5 from '@assets/dune.svg'
import icon_1 from '@assets/gps.svg'
import icon_4 from '@assets/payment.svg'
import photo_1 from '@assets/slider-photo/1.jpeg'
import photo_2 from '@assets/slider-photo/2.jpeg'
import photo_3 from '@assets/slider-photo/3.jpeg'
import photo_4 from '@assets/slider-photo/4.jpeg'
import icon_3 from '@assets/wifi.svg'

import styles from './Slider.module.css'
import './index.css'

const items = [
	{
		photo: photo_1,
		title: 'Light and modern design'
	},
	{
		photo: photo_2,
		title: 'Coffee and breakfast'
	},
	{
		photo: photo_3,
		title: 'Flexible pricing'
	},
	{
		photo: photo_4,
		title: 'Open and private spaces'
	}
]
const Slider: FC = () => {
	const widthRef = useRef<HTMLDivElement>(null)
	const width = useResizeWidth(widthRef)
	const preview = useSetPreview(width)

	return (
		<>
			<div className={styles.slider} ref={widthRef}>
				<div className={styles.slider__title}>
					UBUD SPACE — zones of your productivity
				</div>

				<Swiper
					spaceBetween={14}
					slidesPerView={preview}
					navigation={true}
					modules={[Navigation]}
				>
					{items.map((item, index) => (
						<SwiperSlide key={index} className={styles.slider__item}>
							<div className={styles.blackout}></div>
							<img className={styles.photo} src={item.photo} alt='рhoto' />
							<div className={styles.icon__block}>
								<div className={styles.block__title}>{item.title}</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</>
	)
}

export default Slider
